/* eslint-disable no-useless-concat */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Modal, Card } from "antd";
import "../home/css/index.css";
import Footer from "../../common/components/footer";
import Header from "../../common/components/header";
import LoadingPage from "../../common/components/loading/page";
import { useDimensions } from "../../common/mamager/ScreenManager";
import { Icon } from "@iconify/react";
import {
  getAnnounceAllFetch,
  getAstrologyImportantFetch,
  getPageHomeFetch,
} from "./API";
import { apiServerUrl, serverUrl } from "../../constants";
import parse from "html-react-parser";
import KnowledgeSource from "./components/knowledgeSource";
import UserComment from "./components/userComment";
import Partner from "../aboutMe/components/Partner";
import TalkToUs from "../contract/components/TalkToUs";
import Marquees from "../../common/components/announce/Marquees";
import moment from "moment";
import { getPredictionAllFetch } from "../prediction/API";
import { getCourseAllFetch } from "../course/API";
import { getEBookAllFetch } from "../ebook/API";
import { fontSize, fontWeight } from "@mui/system";

export default function Home({ firebase, user }) {
  const navigate = useNavigate();

  const [announce, setAnnounce] = useState();

  const { width } = useDimensions();
  const [loading, setLoading] = useState(true);

  const [pageHomeData, setPageHomeData] = useState();
  const [astrologyImportantData, setAstrologyImportantData] = useState();

  const audioInterfaceRef = useRef();
  const audioDecidempRef = useRef();
  const audioReboundRef = useRef();

  const [modalAnnounceDetail, setModalAnnounceDetail] = useState({
    isShow: false,
    data: null,
  });

  const [modalNotify, setModalNotify] = useState({
    isShow: false,
    data: null,
  });

  const [modalDeveloping, setModalDeveloping] = useState({
    isShow: false,
    title: null,
    data: null,
  });

  const [loadingRecommend, setLoadingRecommend] = useState(true);
  const [predictionData, setPredictionData] = useState(true);
  const [courseData, setCourseData] = useState(true);
  const [eBookData, setEBookData] = useState(true);
  const [modalRecommend, setModalRecommend] = useState({
    isShow: false,
    title: null,
    data: null,
  });

  const getAnnounceAll = async () => {
    const result = await getAnnounceAllFetch();
    setAnnounce(result); // bannerData
  };

  const getPageHome = async () => {
    let result = await getPageHomeFetch();
    setPageHomeData(result);
  };

  const getAstrologyImportant = async () => {
    let result = await getAstrologyImportantFetch();
    setAstrologyImportantData(result);
  };

  const getRequestBaseApi = async () => {
    setLoading(true);

    setTimeout(async function () {
      await getAnnounceAll();
      await getPageHome();
      await getAstrologyImportant();

      setLoading(false);
    }, 1000);
  };

  const genWelcome = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#262626",
          paddingTop: 12,
          paddingRight: 12,
          paddingLeft: 12,
          paddingBottom: 52,
        }}
      >
        <div style={{ display: "grid" }}>
          <div className="moving my-image">
            <img
              src={`./assets/images/logo/Du-SolarChart-Welcome.png`}
              style={{ height: width > 450 ? "auto" : 100 }}
            />
          </div>
          <label style={{ color: "white", paddingTop: 6 }}>
            <div className="moving my-text">
              {pageHomeData?.titleIntro ? parse(pageHomeData.titleIntro) : []}
            </div>
          </label>
        </div>

        {width > 826 ? <div style={{ width: 40 }}></div> : []}

        {width > 826 ? (
          <div
            style={{
              display: "grid",
              alignItems: "center",
              justifyContent: "center",
              padding: 4,
            }}
          >
            <div className="moving my-menu1">
              <div
                className="menu"
                onClick={() => {
                  audioReboundRef.current.play();
                  setTimeout(function () {
                    navigate("/course");
                    window.scrollTo(0, 0);
                  }, 1000);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#565656",
                    padding: 6,
                    borderRadius: 50,
                    cursor: "pointer",
                  }}
                >
                  <div className="menu-img">
                    <Icon
                      icon="mdi:horse-variant"
                      style={{ color: "white", width: 32, height: 32 }}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: 10 }}>
                  <div className="menu-text">
                    <label style={{ color: "white", cursor: "pointer" }}>
                      คอร์สเรียน
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {/* ----------------------------------------------------- */}
            <div className="moving my-menu2">
              <div
                className="menu"
                onClick={() => {
                  audioReboundRef.current.play();
                  setTimeout(function () {
                    navigate("/prediction");
                    window.scrollTo(0, 0);
                  }, 1000);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#565656",
                    padding: 6,
                    borderRadius: 50,
                    cursor: "pointer",
                  }}
                >
                  <div className="menu-img">
                    <Icon
                      icon="ph:planet-fill"
                      style={{ color: "white", width: 32, height: 32 }}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: 10 }}>
                  <div className="menu-text">
                    <label style={{ color: "white", cursor: "pointer" }}>
                      ห้องโหร ออนไลน์
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {/* ----------------------------------------------------- */}
            <div className="moving my-menu3">
              <div
                className="menu"
                onClick={() => {
                  audioReboundRef.current.play();
                  setTimeout(function () {
                    navigate("/ebook");
                    window.scrollTo(0, 0);
                  }, 1000);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#565656",
                    padding: 6,
                    borderRadius: 50,
                    cursor: "pointer",
                  }}
                >
                  <div className="menu-img">
                    <Icon
                      icon="ion:book-sharp"
                      style={{ color: "white", width: 32, height: 32 }}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: 10 }}>
                  <div className="menu-text">
                    <label style={{ color: "white", cursor: "pointer" }}>
                      e-Books
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {/* ----------------------------------------------------- */}
            <div className="moving my-menu4">
              <div
                className="menu"
                onClick={() => {
                  audioReboundRef.current.play();
                  setTimeout(function () {
                    navigate("/service");
                    window.scrollTo(0, 0);
                  }, 1000);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#565656",
                    padding: 6,
                    borderRadius: 50,
                    cursor: "pointer",
                  }}
                >
                  <div className="menu-img">
                    <Icon
                      icon="carbon:carbon-for-ibm-product"
                      style={{ color: "white", width: 32, height: 32 }}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: 10 }}>
                  <div className="menu-text">
                    <label style={{ color: "white", cursor: "pointer" }}>
                      บริการต่างๆ
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {/* ----------------------------------------------------- */}
            <div className="moving my-menu5">
              <div
                className="menu"
                onClick={() => {
                  audioReboundRef.current.play();
                  setTimeout(function () {
                    navigate("/about-me");
                    window.scrollTo(0, 0);
                  }, 1000);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#565656",
                    padding: 6,
                    borderRadius: 50,
                    cursor: "pointer",
                  }}
                >
                  <div className="menu-img">
                    <Icon
                      icon="f7:building-2"
                      style={{ color: "white", width: 32, height: 32 }}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: 10 }}>
                  <div className="menu-text">
                    <label style={{ color: "white", cursor: "pointer" }}>
                      เกี่ยวกับเรา
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {/* ----------------------------------------------------- */}
            <div className="moving my-menu6">
              <div
                className="menu"
                onClick={() => {
                  audioReboundRef.current.play();
                  setTimeout(function () {
                    navigate("/contact");
                    window.scrollTo(0, 0);
                  }, 1000);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#565656",
                    padding: 6,
                    borderRadius: 50,
                    cursor: "pointer",
                  }}
                >
                  <div className="menu-img">
                    <Icon
                      icon="streamline:contact-phonebook-2-solid"
                      style={{ color: "white", width: 32, height: 32 }}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: 10 }}>
                  <div className="menu-text">
                    <label style={{ color: "white", cursor: "pointer" }}>
                      ติดต่อเรา
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          []
        )}
      </div>
    );
  };

  const genBanner = () => {
    return (
      <div
        style={{
          display: width > 833 ? "flex" : "grid",
          alignItems: "flex-start",
          justifyContent: "center",
          marginTop: -40,
        }}
      >
        {announce?.map((item) => {
          return (
            <div
              style={{
                paddingLeft: 4,
                paddingRight: 4,
                paddingBottom: width > 833 ? 0 : 12,
              }}
            >
              <div className="announce-card">
                <div style={{ width: 250, height: 440, textAlign: "center" }}>
                  <img
                    src={`${serverUrl}/${item.imgCoverURL}`}
                    style={{
                      width: 250,
                      borderTopLeftRadius: 8,
                      borderTopRightRadius: 8,
                    }}
                  />
                  <label style={{ fontWeight: 600 }}>{item.title}</label>
                  <label>{parse(item.subTitle)}</label>
                  <label
                    style={{
                      textDecoration: "underline",
                      textAlign: "end",
                      color: "#FF7F00",
                      fontWeight: 500,
                      cursor: "pointer",
                      height: 12,
                    }}
                    onClick={() => {
                      audioReboundRef.current.play();
                      if (item?.link) {
                        window.open(item.link);
                      } else {
                        setModalAnnounceDetail({
                          isShow: true,
                          data: item,
                        });
                      }
                    }}
                  >
                    อ่านต่อ
                  </label>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const genImportanceAstrology = () => {
    return (
      <div
        style={{
          display: "grid",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: 64,
          paddingLeft: 12,
          paddingRight: 12,
          textAlign: "center",
        }}
      >
        <label style={{ fontSize: 30, fontWeight: 600 }}>
          {astrologyImportantData?.title}
        </label>
        {astrologyImportantData?.subTitle ? (
          <label style={{ paddingTop: 24 }}>
            {astrologyImportantData?.subTitle}
          </label>
        ) : (
          []
        )}
        <div style={{ paddingTop: 12, display: "flex" }}>
          <Row>
            <Col lg={10} md={11} sm={24}>
              <img
                src={`${serverUrl}/${astrologyImportantData?.imageUrl}`}
                style={{ width: 320, borderRadius: 8 }}
              />
            </Col>
            <Col lg={14} md={11} sm={24}>
              <div
                style={{
                  display: "grid",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  textAlign: "left",
                  paddingLeft: width > 767 ? 10 : 0,
                }}
              >
                <label style={{ marginTop: width > 767 ? -15 : 0 }}>
                  {astrologyImportantData?.detail
                    ? parse(astrologyImportantData?.detail)
                    : []}
                </label>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  const genKnowledgeSource = () => {
    return (
      <div
        style={{
          display: "grid",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          paddingTop: 24,
          paddingBottom: 24,
        }}
      >
        <label style={{ fontSize: 22, fontWeight: 600 }}>
          {pageHomeData?.titleknowledge}
        </label>
        <div style={{ paddingTop: 12 }}>
          <KnowledgeSource />
        </div>
      </div>
    );
  };

  const getRecommendApi = async () => {
    setLoadingRecommend(true);

    const resultPrediction = await getPredictionAllFetch();
    // console.log("getPredictionAllFetch : ", resultPrediction);
    setPredictionData(resultPrediction);

    const resultCourse = await getCourseAllFetch();
    // console.log("getCourseAllFetch : ", resultCourse);
    setCourseData(resultCourse);

    let resultEBook = await getEBookAllFetch();
    // console.log("getEBookAllFetch : ", resultEBook);
    setEBookData(resultEBook);

    setModalRecommend({
      isShow: true,
      title: null,
      data: null,
    });

    setLoadingRecommend(false);
  };

  useMemo(() => {
    // สร้าง object ของ Date ใหม่ (จะใช้เวลาปัจจุบัน)
    // var currentTime = new Date().toLocaleString("en-US", {
    //   timeZone: "Asia/Bangkok",
    // });

    // // สร้าง object ของ Date ที่เราต้องการเปรียบเทียบ (เวลาที่เราต้องการ)
    // var targetTime = new Date("2024-04-23T06:00:00").toLocaleString("en-US", {
    //   timeZone: "Asia/Bangkok",
    // });

    // // เปรียบเทียบเวลาปัจจุบันกับเวลาเป้าหมาย
    // if (currentTime > targetTime) {
    //   // ...
    // } else {
    //   setModalNotify({ isShow: true, title: null, data: null });
    //   setModalDeveloping({ isShow: true, title: null, data: null });
    // }

    getRecommendApi();
  }, []);

  useEffect(() => {
    getRequestBaseApi();
  }, []);

  return (
    <>
      <Header firebase={firebase} user={user} />

      {!loading ? (
        <>
          <Marquees />

          {genWelcome()}
          {genBanner()}
          <div
            style={{
              paddingLeft: width > 900 ? "10%" : 0,
              paddingRight: width > 900 ? "10%" : 0,
            }}
          >
            {genImportanceAstrology()}
          </div>

          <div style={{ paddingTop: 24, paddingBottom: 24 }}>
            <div
              style={{
                backgroundImage:
                  "url(" +
                  `${serverUrl}/${pageHomeData?.imageIntro2BgUrl}` +
                  ")",
                backgroundSize: "100%",
                width: "100% auto",
                height: "100% auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 12,
              }}
            >
              <img
                src={`${serverUrl}/${pageHomeData?.imageIntro2Url}`}
                style={{ width: 320, borderRadius: 8 }}
              />
            </div>
          </div>

          {genKnowledgeSource()}

          <div style={{ paddingTop: 24, paddingBottom: 24 }}>
            <UserComment />
          </div>

          <div style={{ paddingTop: 24, paddingBottom: 0 }}>
            <div
              style={{
                backgroundColor: "#262626",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: 250,
                paddingLeft: width > 900 ? "10%" : 12,
                paddingRight: width > 900 ? "10%" : 12,
              }}
            >
              <label>
                {pageHomeData?.titleIntroFooter
                  ? parse(pageHomeData?.titleIntroFooter)
                  : []}
              </label>
            </div>
          </div>

          <Partner />

          <TalkToUs />
        </>
      ) : (
        <article style={{ backgroundColor: "#262626" }}>
          <LoadingPage loading={loading} />
        </article>
      )}

      <Footer />

      <Modal
        maskClosable={false}
        title={<span>รายละเอียด</span>}
        visible={modalAnnounceDetail.isShow}
        width={590}
        onCancel={() => {
          // close modal
          setModalAnnounceDetail({ isShow: false, data: null });
        }}
        footer={[]}
      >
        <Row>
          <Col span={24}>
            <img
              src={`${serverUrl}/${modalAnnounceDetail?.data?.imgCoverURL}`}
              style={{
                height: 150,
                backgroundColor: "#EEEEEE",
                borderRadius: 8,
              }}
            />
          </Col>

          <Col span={24}>
            <label style={{ fontWeight: 600 }}>
              {modalAnnounceDetail?.data?.title}
            </label>
          </Col>

          <Col span={24}>
            <label>
              {modalAnnounceDetail?.data?.subTitle
                ? parse(modalAnnounceDetail?.data?.subTitle)
                : []}
            </label>
          </Col>
        </Row>
      </Modal>

      <Modal
        title={
          <strong>
            <label style={{ fontSize: 30 }}>คำเตือน! </label>
          </strong>
        }
        visible={modalNotify.isShow}
        onCancel={() => {
          setModalNotify({ isShow: false, title: null, data: null });
        }}
        width={600}
        footer={[]}
      >
        <Row gutter={[0, 0]}>
          <Col span={24}>
            <label style={{ fontSize: 26 }}>
              ทุกข้อมูลในเว็บไซต์ที่เป็นข้อความ รูปภาพ วิดีโอ eBook
              ล้วนเป็นทรัพสินทางปัญญาภายใต้ชื่อ สถาบันโหราศาสตร์วิตเตอ-กรุงเทพ
              โดย ดร.ดวง (ยูเรเนียนกรุ๊ปประเทศไทย 1978)
            </label>
          </Col>
          <Col span={24}>
            <label style={{ fontSize: 26, color: "red" }}>
              * ห้ามคัดลอกเผยเเพร่ข้อมูลเนื้อหาในเว็บไซต์ทุกกรณี
              โดยไม่ได้รับอนุญาต !
            </label>
          </Col>
        </Row>
      </Modal>

      <Modal
        maskClosable={false}
        closable={false}
        title={
          <strong>
            <label style={{ fontSize: 30 }}>เรียนผู้เข้าชมทุกท่าน !</label>
          </strong>
        }
        visible={modalDeveloping.isShow}
        width={600}
        footer={[]}
      >
        <Row gutter={[0, 0]}>
          <Col span={24}>
            <label style={{ fontSize: 26 }}>
              ขณะนี้เว็บไซต์ทั้งหมดพร้อมใช้งานแล้ว
            </label>
          </Col>

          <Col span={24}>
            <label style={{ fontSize: 26 }}>
              แต่จะเปิดเป็น{" "}
              <label
                style={{ fontSize: 26 }}
                onClick={() => {
                  setModalDeveloping({
                    isShow: false,
                    title: null,
                    data: null,
                  });
                }}
              >
                ปฐมฤกษ์
              </label>{" "}
              ให้ใช้งานในวันที่ 23 เมษายน 2567 เป็นต้นไป
            </label>
          </Col>
          <Col span={24}>
            <label style={{ fontSize: 26 }}>
              อย่าลืมแวะกลับเข้ามานะ มีเรื่องราวที่พลาดไม่ได้มากมาย
            </label>
          </Col>
        </Row>
      </Modal>

      <Modal
        title={
          <strong>
            <label className="topic-color-bold">{modalRecommend.title}</label>
          </strong>
        }
        visible={modalRecommend.isShow}
        onCancel={() => {
          setModalRecommend({ isShow: false, title: null, data: null });
        }}
        width={900}
        footer={[]}
        className="modalRecommend"
      >
        {!loadingRecommend ? (
          <>
            <Row style={{ paddingBottom: 12 }}>
              <Col span={24}>
                <lanel style={{ fontWeight: 600, fontSize: 26, color: "white" }}>คอร์สเรียน เเนะนำ</lanel>
              </Col>

              <Col span={24}>
                <div style={{ paddingTop: 2, paddingBottom: 12 }}>
                  <Row gutter={[12, 12]}>
                    {courseData?.map((val) => {
                      return (
                        <Col xs={24} md={12} xl={8}>
                          <Card
                            hoverable
                            style={{}}
                            onClick={() => navigate("/course")}
                          >
                            <div style={{ display: "grid" }}>
                              <div>
                                <img
                                  src={`${serverUrl}/${val.imgCoverURL}`}
                                  style={{
                                    objectFit: "cover",
                                    width: "100%",
                                    height: 150,
                                  }}
                                />
                              </div>
                              <div style={{ height: 70 }}>{val.title}</div>
                              <div style={{ fontWeight: 600, fontSize: 20 }}>
                                ราคา{" "}
                                {Intl.NumberFormat("en", {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                }).format(Number(val.price))}{" "}
                                บาท
                              </div>
                            </div>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </Col>
            </Row>

            <Row style={{ paddingBottom: 12 }}>
              <Col span={24}>
                <lanel style={{ fontWeight: 600, fontSize: 26, color: "white" }}>ห้องโหร ออนไลน์ เเนะนำ</lanel>
              </Col>

              <Col span={24}>
                <div style={{ paddingTop: 2, paddingBottom: 12 }}>
                  <Row gutter={[12, 12]}>
                    {predictionData?.map((val) => {
                      return (
                        <Col xs={24} md={12} xl={8}>
                          <Card
                            hoverable
                            style={{}}
                            onClick={() => navigate("/prediction")}
                          >
                            <div style={{ display: "grid" }}>
                              <div>
                                <img
                                  src={`${serverUrl}/${val.imgCoverURL}`}
                                  style={{
                                    objectFit: "cover",
                                    width: "100%",
                                    height: 150,
                                  }}
                                />
                              </div>
                              <div style={{ height: 70 }}>{val.title}</div>
                              <div style={{ fontWeight: 600, fontSize: 20 }}>
                                ราคา{" "}
                                {Intl.NumberFormat("en", {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                }).format(Number(val.price))}{" "}
                                บาท
                              </div>
                            </div>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <lanel style={{ fontWeight: 600, fontSize: 26, color: "white" }}>e-Book เเนะนำ</lanel>
              </Col>

              <Col span={24}>
                <div style={{ paddingTop: 2, paddingBottom: 12 }}>
                  <Row gutter={[12, 12]}>
                    {eBookData?.map((val) => {
                      return (
                        <Col xs={24} md={12} xl={8}>
                          <Card
                            hoverable
                            style={{}}
                            onClick={() => navigate("/ebook")}
                          >
                            <div style={{ display: "grid" }}>
                              <div>
                                <img
                                  src={`${serverUrl}/${val.imgCoverURL}`}
                                  style={{
                                    objectFit: "cover",
                                    width: "100%",
                                    height: 300,
                                  }}
                                />
                              </div>
                              <div style={{ height: 180 }}>{val.title}</div>
                              <div>{}</div>
                            </div>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          []
        )}
      </Modal>

      <audio ref={audioInterfaceRef}>
        <source src="./assets/audio/interface.mp3" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      <audio ref={audioReboundRef}>
        <source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      <audio ref={audioDecidempRef}>
        <source src="./assets/audio/decidemp.mp3" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </>
  );
}
